import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { Col, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy'
import { Skeleton } from '@mui/material';
import LazyLoad from "react-lazy-load";
import { isMobile } from "react-device-detect";


const BannerSlider = (props) => {

  const videoEl = useRef(null);
  const attemptPlay = () => {
    setTimeout(() => setLoader(false), 500);
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  const [loader, setLoader] = useState(true);

  return (
    <>
     <LazyLoad className='lazyLoad-heroBanner' height={!isMobile?700:400} width="100%" threshold={ 0.90}>
      <section className="dfl_mid_video_section">
        <div className={`${props?.container && 'rcs_custome_container'}`}>
          {loader ?
            <Skeleton
              variant="text"
              animation="wave"
              width={1410}
              height={700}
              style={{ transform: "unset", margin: "0 auto" }}
            />
            :
            <div className="rcs_hero_img_d">
              {
                props?.title && <div className="title-box2 text-center"> <h2 className="hand_craft_video_heading play-font">Handcrafted Locally To The Highest Standards</h2></div>
              }

              <Row className="m-auto w-100">
                <Col lg={12} md={12} className="p-0">
                  <div className="rcs_home_video_main">
                    <ReactPlayer
                      url={props?.url}
                      width="100%"
                      height={`${props?.height ? props?.height : '100%'}`}
                      poster="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
                      playing
                      loop
                      muted
                      controls={false}
                      playsinline
                      // preload="none"
                      fallback={()=>'loading'}
                      className={`${props?.height && 'object-fit-cover'}`}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          }
        </div>
      </section>
      </LazyLoad>
    </>
  );
};

export default React.memo(BannerSlider);

import React, { useState, useEffect } from "react";

function GoogleReviews() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    service.getDetails(
      {
        placeId: "Diamonds+For+Less",
        fields: ["reviews"],
      },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setReviews(place.reviews);
          console.log(place)
        }
      }
    );
  }, []);

  return (
    <div>
      {reviews.map((review) => (
        <div key={review.time}>
          <p>{review.text}</p>
          <p>
            By {review.author_name} on{" "}
            {new Date(review.time * 1000).toLocaleDateString()}
          </p>
        </div>
      ))}
    </div>
  );
}

export default GoogleReviews;

import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import DImg from '../../../src/Assets/images/DFL/d-diamond.webp';
import CalanderImg from '../../../src/Assets/images/DFL/calander.png';
import HomeBannerVider from '../../../src/Assets/images/DFL/home-banner-video.mp4';
import HomeBannerVider320 from '../../../src/Assets/images/DFL/home-banner-video-320.mp4';
import ReactPlayer from 'react-player/lazy';
import { isMobile } from 'react-device-detect';
import LazyLoad from 'react-lazy-load';

const BannerWithTextAndVideo = () => {
  const history = useHistory();
  return (
      <LazyLoad className='lazyLoad-heroBanner' height={!isMobile?715:770} width="100%" threshold={0.90}>
    <div className="dfl_home_banner_with_text_section">
      <Container fluid>
        <Row>
          <Col
            sm={12}
            md={6}
            lg={6}
            className="dfl_home_order2 dfl_banner_vider_outer_box "
          >
            <div className="dfl_banner_with_text_and_img_box">
              <div className="dfl_banner_imgg_box">
                <Image src={DImg} alt="D Image" className="img-fluid" />
              </div>
              <div className="dfl_banner_with_text_inner_box">
                <h3>The #1 Name In Diamonds in Toronto - Since 1985</h3>
                <h6>CREATE YOUR OWN</h6>
                <h2>Engagement Rings</h2>
                <h5>
                  Tired Of Virtual Inventories?{' '}
                  <strong
                    onClick={() => history.push('/contact-us')}
                    className="cursor-pointer"
                  >
                    <u>VISIT DFL</u>
                  </strong>
                </h5>
                <ul>
                  <li>
                    Largest IN STOCK Selection Of Earth & Lab Loose Diamonds
                  </li>
                  <li>1000’s of IN STOCK Ring Styles</li>
                </ul>
                <div className="dfl_cal_and_texttt">
                  <NavLink
                    to="/make-an-appointment"
                    className="dfl_text_and_book"
                  >
                    <Image src={CalanderImg} alt="book Consultation" />
                    Book Your 1 on 1 Free Consultation
                  </NavLink>
                </div>

                <div className="dfl_home_banner_btn_box">
                  <NavLink
                    to="/ringsettings"
                    className="dfl_home_btn_start_with_setting"
                  >
                    START WITH SETTING
                  </NavLink>
                  <NavLink
                    to="/diamonds"
                    className="dfl_home_btn_start_with_diamonds"
                  >
                    START WITH A DIAMOND
                  </NavLink>
                </div>
              </div>
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={6}
            className="dfl_home_order1 dfl_banner_vider_outer_box"
          >
            <div className="dfl_video_outer_boxxx">
              <ReactPlayer
                url={HomeBannerVider}
                width="100%"
                height="100%"
                poster="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
                playing
                loop
                muted
                controls={false}
                playsinline
                // preload="none"
                className="dfl_home_video_520desktop_view"
              />
              <ReactPlayer
                url={HomeBannerVider320}
                poster="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
                playing
                loop
                muted
                controls={false}
                playsinline
                // preload="none"
                className="dfl_home_video_320_mobile_view"
                width="100%"
                height="100%"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
      </LazyLoad>
  );
};

export default BannerWithTextAndVideo;

import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import calendarIcn from "../../Assets/images/calender-icn.webp";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LooseDiamond = () => {
  const history = useHistory();

  return (
    <>
      <section className="sj_loose_diamond_section">
        <Container>
          <Row className="m-auto w-100">
            <Col lg={9} md={12} className="m-auto">
              <div className="sj_loose_diamond_content">
                <h2>CONSULT WITH A DIAMOND SPECIALIST</h2>
                <p>
                  Visit our office to see the largest collection of diamonds and Jewellery in Toronto.
                </p>
                <Button
                  variant="outline-dark"
                  className="sj_border_btn"
                  onClick={() => history.push("/make-an-appointment")}
                >
                  <LazyLoadImage alt="" src={calendarIcn} />   Schedule an Appointment
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default React.memo(LooseDiamond);

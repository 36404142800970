import React from 'react';
import "../../Assets/css/home.css";
import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Ex1 from "../../Assets/images/DFL/engagement_col.webp";
import Ex2 from "../../Assets/images/DFL/necklace_col.webp";
import Ex3 from "../../Assets/images/DFL/wedding_rings_col.webp";
import Ex4 from "../../Assets/images/DFL/earrings_col.webp"
import Ex5 from "../../Assets/images/DFL/bracelet_col.webp"
import { LazyLoadImage } from "react-lazy-load-image-component";

const ExploreCollectionDfl = () => {

    return (
        <>
            <section className='dfl_diamond_exp_section'>
                <Container className='rcs_custome_container'>

                    <Row>
                        <Col>
                            <div className="sec-category">

                                <div className="title-box2 text-center">
                                    <h2 className="title30 dark play-font">Explore Our Collection</h2>
                                </div>

                                <div className="container sec-category1">
                                    <div className="clearfix">
                                        <div className="collection_section">
                                            <div className="collection_section_content">
                                                <Row>


                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 create_diamond_img pull-right">
                                                        <NavLink to="/engagement-rings-toronto" className="image_url">
                                                            <LazyLoadImage src={Ex1} alt="Engagement Rings" />
                                                        </NavLink>
                                                    </div>

                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 create_diamond_section">

                                                        <div className="create_diamond_section_inner Dfl_Exp_left_cont">
                                                            <h6>Rings </h6>
                                                            <h4>Engagement Rings</h4>
                                                            <p className="title16">Visit DFL to explore the largest selection of engagement rings in Toronto and discover your ideal style at wholesale prices.</p>
                                                            <NavLink to="/engagement-rings-toronto" className="start_btn">Shop Now</NavLink>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix">
                                        <div className="collection_section">
                                            <div className="collection_section_content">
                                                <Row>

                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 create_diamond_section">

                                                        <div className="create_diamond_section_inner Dfl_Exp_right_cont">
                                                            <h6>Necklace </h6>
                                                            <h4>Necklaces</h4>
                                                            <p className="title16">Browse through our curated inventory of classic or one of kind diamond pendants in Toronto and find the perfect gift for all occasions.</p>
                                                            <NavLink to="/jewellery/necklaces" className="start_btn">Shop Now</NavLink>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 create_diamond_img">
                                                        <NavLink to="/jewellery/necklaces" className="image_url">
                                                            <LazyLoadImage src={Ex2} alt="necklace" />
                                                        </NavLink>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="clearfix">
                                        <div className="collection_section">
                                            <div className="collection_section_content">
                                                <Row>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 create_diamond_img">
                                                        <NavLink to="/jewellery/earrings" className="image_url">
                                                            <LazyLoadImage src={Ex4} alt="Earrings" />
                                                        </NavLink>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 create_diamond_section">

                                                        <div className="create_diamond_section_inner Dfl_Exp_left_cont">
                                                            <h6>Earrings </h6>
                                                            <h4>Earrings</h4>
                                                            {/* <p className="title16">Look your best every day! </p> */}
                                                            <p className="title16">DFL offers a variety of styles, including Diamond Studs, Diamond Hoops, and more! Let our diamond experts assist you select the perfect diamond earrings in Toronto.</p>
                                                            <NavLink to="/jewellery/diamond-earrings" className="start_btn">Shop Now</NavLink>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="clearfix">
                                        <div className="collection_section">
                                            <div className="collection_section_content">
                                                <Row>
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 create_diamond_section">

                                                        <div className="create_diamond_section_inner Dfl_Exp_right_cont">
                                                            <h6>Rings </h6>
                                                            <h4>Wedding Rings</h4>
                                                            <p className="title16">Find the perfect symbol of your love. Discover eternity diamond rings, classic bands, and unique wedding rings in Toronto.</p>
                                                            <NavLink to="/jewellery/wedding-anniversary-bands" className="start_btn">Shop Now</NavLink>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 create_diamond_img pull-right">

                                                        <NavLink to="/jewellery/wedding-anniversary-bands" className="image_url">
                                                            <LazyLoadImage src={Ex3} alt="Earrings" />
                                                        </NavLink>
                                                    </div>


                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix">
                                        <div className="collection_section">
                                            <div className="collection_section_content">
                                                <Row>


                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 create_diamond_img pull-right">
                                                        <NavLink to="/jewellery/bracelets" className="image_url">
                                                            <LazyLoadImage src={Ex5} alt="bracelet" />
                                                        </NavLink>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 create_diamond_section">

                                                        <div className="create_diamond_section_inner Dfl_Exp_left_cont">
                                                            <h6>Bracelets </h6>
                                                            <h4>Bracelets</h4>
                                                            <p className="title16">Choose from our carefully curated assortment of diamond bracelets in Toronto and make a statement like no other with a stunning tennis bracelet.</p>
                                                            <NavLink to="/jewellery/diamond-bracelets" className="start_btn">Shop Now</NavLink>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>


                </Container>

            </section>

        </>
    )
}

export default React.memo(ExploreCollectionDfl);
import React, { useEffect, useState } from "react";
import "../../Assets/css/home.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
// import C1 from "../../Assets/images/DFL/customer/c-1.webp";
// import C2 from "../../Assets/images/DFL/customer/c-2.webp";
// import C3 from "../../Assets/images/DFL/customer/c-3.webp";
// import C5 from "../../Assets/images/DFL/customer/c-5.webp";
// import Ca1 from "../../Assets/images/DFL/customer/c-a1.webp";
// import C6 from "../../Assets/images/DFL/customer/c-5.webp";
// import C7 from "../../Assets/images/DFL/customer/c-6.webp";
// import Ca2 from "../../Assets/images/DFL/customer/c-a2.webp";
// import C8 from "../../Assets/images/DFL/customer/c-7.webp";
// import C9 from "../../Assets/images/DFL/customer/c-8.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal";
import "react-gallery-carousel/dist/index.css";
import { Link, NavLink } from "react-router-dom";
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import a1 from "../../Assets/images/DFL/client-proposal-img/a1.webp";
// import a2 from "../../Assets/images/DFL/client-proposal-img/a2.webp";
import a3 from "../../Assets/images/DFL/client-proposal-img/a3.webp";
import a4 from "../../Assets/images/DFL/client-proposal-img/a4.webp";
import b1 from "../../Assets/images/DFL/client-proposal-img/b1.webp";
import b2 from "../../Assets/images/DFL/client-proposal-img/b2.webp";
import b3 from "../../Assets/images/DFL/client-proposal-img/b3.webp";
import b4 from "../../Assets/images/DFL/client-proposal-img/b4.webp";
import c1 from "../../Assets/images/DFL/client-proposal-img/c1.webp";
import c2 from "../../Assets/images/DFL/client-proposal-img/c2.webp";
import c3 from "../../Assets/images/DFL/client-proposal-img/c3.webp";
import c4 from "../../Assets/images/DFL/client-proposal-img/c4.webp";
import c5 from "../../Assets/images/DFL/client-proposal-img/c5.webp";
import d1 from "../../Assets/images/DFL/client-proposal-img/d1.webp";
import d2 from "../../Assets/images/DFL/client-proposal-img/d2.webp";
// import e1 from "../../Assets/images/DFL/client-proposal-img/e1.webp";
import e2 from "../../Assets/images/DFL/client-proposal-img/e2.webp";
import e3 from "../../Assets/images/DFL/client-proposal-img/e3.webp";
import e4 from "../../Assets/images/DFL/client-proposal-img/e4.webp";
import e5 from "../../Assets/images/DFL/client-proposal-img/e5.webp";
import f1 from "../../Assets/images/DFL/client-proposal-img/f1.webp";
import f2 from "../../Assets/images/DFL/client-proposal-img/f2.webp";
import f3 from "../../Assets/images/DFL/client-proposal-img/f3.webp";
import g1 from "../../Assets/images/DFL/client-proposal-img/g1.webp";
import g2 from "../../Assets/images/DFL/client-proposal-img/g2.webp";
import g3 from "../../Assets/images/DFL/client-proposal-img/g3.webp";
import h1 from "../../Assets/images/DFL/client-proposal-img/h1.webp";
import h2 from "../../Assets/images/DFL/client-proposal-img/h2.webp";
import h3 from "../../Assets/images/DFL/client-proposal-img/h3.webp";
import i1 from "../../Assets/images/DFL/client-proposal-img/i1.webp";
import i2 from "../../Assets/images/DFL/client-proposal-img/i2.webp";
import i3 from "../../Assets/images/DFL/client-proposal-img/i3.webp";

import j1 from "../../Assets/images/DFL/client-proposal-img/j1.webp";
import j2 from "../../Assets/images/DFL/client-proposal-img/j2.webp";
import j3 from "../../Assets/images/DFL/client-proposal-img/j3.webp";
import k1 from "../../Assets/images/DFL/client-proposal-img/k1.webp";
import k2 from "../../Assets/images/DFL/client-proposal-img/k2.webp";
import k3 from "../../Assets/images/DFL/client-proposal-img/k3.webp";
import L1 from "../../Assets/images/DFL/client-proposal-img/L1.webp";
import L2 from "../../Assets/images/DFL/client-proposal-img/L2.webp";
import L3 from "../../Assets/images/DFL/client-proposal-img/L3.webp";
import m1 from "../../Assets/images/DFL/client-proposal-img/m1.webp";
import m2 from "../../Assets/images/DFL/client-proposal-img/m2.webp";
import m3 from "../../Assets/images/DFL/client-proposal-img/m3.webp";
import n1 from "../../Assets/images/DFL/client-proposal-img/n1.webp";
import n2 from "../../Assets/images/DFL/client-proposal-img/n2.webp";
import o1 from "../../Assets/images/DFL/client-proposal-img/o1.webp";
import o2 from "../../Assets/images/DFL/client-proposal-img/o2.webp";
import p1 from "../../Assets/images/DFL/client-proposal-img/p1.webp";
import p2 from "../../Assets/images/DFL/client-proposal-img/p2.webp";
import p3 from "../../Assets/images/DFL/client-proposal-img/p3.webp";
import q1 from "../../Assets/images/DFL/client-proposal-img/q1.webp";
import q2 from "../../Assets/images/DFL/client-proposal-img/q2.webp";
import q3 from "../../Assets/images/DFL/client-proposal-img/q3.webp";
import r1 from "../../Assets/images/DFL/client-proposal-img/r1.webp";
import r2 from "../../Assets/images/DFL/client-proposal-img/r2.webp";
import S1 from "../../Assets/images/DFL/client-proposal-img/s1.webp";
import S2 from "../../Assets/images/DFL/client-proposal-img/s2.webp";
import S3 from "../../Assets/images/DFL/client-proposal-img/s3.webp";
import t1 from "../../Assets/images/DFL/client-proposal-img/t1.webp";
import t2 from "../../Assets/images/DFL/client-proposal-img/t2.webp";
import t3 from "../../Assets/images/DFL/client-proposal-img/t3.webp";
import u1 from "../../Assets/images/DFL/client-proposal-img/u1.webp";
import u2 from "../../Assets/images/DFL/client-proposal-img/u2.webp";
import u3 from "../../Assets/images/DFL/client-proposal-img/u3.webp";
import v1 from "../../Assets/images/DFL/client-proposal-img/v1.webp";
import v2 from "../../Assets/images/DFL/client-proposal-img/v2.webp";
import v3 from "../../Assets/images/DFL/client-proposal-img/v3.webp";


import w1 from "../../Assets/images/DFL/client-proposal-img/w1.webp"
import w2 from "../../Assets/images/DFL/client-proposal-img/w2.webp"
import w3 from "../../Assets/images/DFL/client-proposal-img/w3.webp"

import x1 from "../../Assets/images/DFL/client-proposal-img/x1.webp"
import x2 from "../../Assets/images/DFL/client-proposal-img/x2.webp"
import x3 from "../../Assets/images/DFL/client-proposal-img/x3.webp"

import y1 from "../../Assets/images/DFL/client-proposal-img/y1.webp"
import y2 from "../../Assets/images/DFL/client-proposal-img/y2.webp"
import y3 from "../../Assets/images/DFL/client-proposal-img/y3.webp"

import z1 from "../../Assets/images/DFL/client-proposal-img/z1.webp"
import z2 from "../../Assets/images/DFL/client-proposal-img/z2.webp"
import z3 from "../../Assets/images/DFL/client-proposal-img/z3.webp"

import a21 from "../../Assets/images/DFL/client-proposal-img/a21.webp"
import a22 from "../../Assets/images/DFL/client-proposal-img/a22.webp"
import a23 from "../../Assets/images/DFL/client-proposal-img/a23.webp"

import b21 from "../../Assets/images/DFL/client-proposal-img/b21.webp"
import b22 from "../../Assets/images/DFL/client-proposal-img/b22.webp"
import b23 from "../../Assets/images/DFL/client-proposal-img/b23.webp"
import c22 from "../../Assets/images/DFL/client-proposal-img/C22.webp"
import c21 from "../../Assets/images/DFL/client-proposal-img/C21.webp"
import d21 from "../../Assets/images/DFL/client-proposal-img/d21.webp"
import d22 from "../../Assets/images/DFL/client-proposal-img/d22.webp"
import d23 from "../../Assets/images/DFL/client-proposal-img/d23.webp"
import e21 from "../../Assets/images/DFL/client-proposal-img/e21.webp"
import e22 from "../../Assets/images/DFL/client-proposal-img/e22.webp"
import e23 from "../../Assets/images/DFL/client-proposal-img/e23.webp"
import f21 from "../../Assets/images/DFL/client-proposal-img/f21.webp"
import f22 from "../../Assets/images/DFL/client-proposal-img/f22.webp"
import f23 from "../../Assets/images/DFL/client-proposal-img/f23.webp"
import g21 from "../../Assets/images/DFL/client-proposal-img/g21.webp"
import g22 from "../../Assets/images/DFL/client-proposal-img/g22.webp"
import g23 from "../../Assets/images/DFL/client-proposal-img/g23.webp"


import aa1 from "../../Assets/images/DFL/client-proposal-img/aa1.webp"
import aa2 from "../../Assets/images/DFL/client-proposal-img/aa2.webp"
import aa3 from "../../Assets/images/DFL/client-proposal-img/aa3.webp"

import bb1 from "../../Assets/images/DFL/client-proposal-img/bb1.webp"
import bb2 from "../../Assets/images/DFL/client-proposal-img/bb2.webp"
import bb3 from "../../Assets/images/DFL/client-proposal-img/bb3.webp"

import cc1 from "../../Assets/images/DFL/client-proposal-img/cc1.webp"
import cc2 from "../../Assets/images/DFL/client-proposal-img/cc2.webp"
import cc3 from "../../Assets/images/DFL/client-proposal-img/cc3.webp"

import { useLocation } from 'react-router-dom';

import { SettingsRemote } from "@material-ui/icons";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import { useMediaQuery } from 'react-responsive'
import LazyLoad from "react-lazy-load";
import axios from "axios";
import { base_url } from "../../Helpers/request";
import { clientPraposal } from "../../Reducer/metaReducer";
import { useDispatch } from "react-redux";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const itemData = [
  {
    id: 38,
    img: cc3,
    title: 'Monica & Adam',
    rows: 2,
    cols: 2,
  },
  {
    id: 37,
    img: bb1,
    title: 'Sonia & Bashar',
    rows: 1,
    cols: 1,
  },
  {
    id: 36,
    img: aa2,
    title: 'Travis & Cassy',
    rows: 1,
    cols: 1,
  },
  {
    id: 35,
    img: g23,
    title: 'Sarah & Gianluca',
    rows: 1,
    cols: 1,
  },
  {
    id: 34,
    img: f21,
    title: 'Carly & Ali',
    rows: 1,
    cols: 1,
  },
  {
    id: 33,
    img: e23,
    title: 'Alecia & Vidal',
    rows: 1,
    cols: 1,
  },
  {
    id: 32,
    img: d22,
    title: 'Hailey & Patrick',
    rows: 1,
    cols: 1,
  },
  {
    id: 31,
    img: c22,
    title: 'Sheba & Atilla',
    rows: 1,
    cols: 1,
  },
  {
    id: 30,
    img: b22,
    title: 'Komel & Ravin',
    rows: 1,
    cols: 1,
  },
  {
    id: 29,
    img: a22,
    title: 'Roxana & Derry',
    rows: 1,
    cols: 1,
  },
  {
    id: 28,
    img: z3,
    title: 'Noosh & Jarrett',
    rows: 1,
    cols: 1,
  },
  {
    id: 27,
    img: y1,
    title: 'Jess & Josh',
    rows: 1,
    cols: 1,
  },
  {
    id: 26,
    img: x1,
    title: 'Emily & Jordan',
    rows: 1,
    cols: 1,
  },
  {
    id: 25,
    img: w1,
    title: 'Natalie & Jean',
    rows: 1,
    cols: 1,
  },
  {
    id: 24,
    img: v3,
    title: 'Alex & Adam',
    rows: 1,
    cols: 1,
  },
  {
    id: 23,
    img: u1,
    title: 'Jelena & Vince',
    rows: 1,
    cols: 1,
  },
  {
    id: 22,
    img: t1,
    title: 'Christina & Curtis',
    rows: 1,
    cols: 1,
  },
  {
    id: 21,
    img: r2,
    title: 'Vickie & Will',
    rows: 1,
    cols: 1,
  },
  {
    id: 20,
    img: q1,
    title: 'Anita & Sidney',
    rows: 1,
    cols: 1,
  },
  {
    id: 19,
    img: p1,
    title: 'Nikoletta & Daniel',
    rows: 1,
    cols: 1,
  },
  {
    id: 18,
    img: o2,
    title: 'Brittany & Roy',
    rows: 1,
    cols: 1,
  },
  {
    id: 17,
    img: n1,
    title: 'Krystyna & Volodymyr',
    rows: 1,
    cols: 1,
  },
  {
    id: 16,
    img: m2,
    title: 'Jotika & Steve',
    rows: 1,
    cols: 1,
  },
  {
    id: 15,
    img: L1,
    title: 'Abbey & Ajkurish',
    rows: 1,
    cols: 1,
  },
  {
    id: 12,
    img: j1,
    title: 'Sara & Jesse',
    rows: 1,
    cols: 1,
  },
  {
    id: 13,
    img: k2,
    title: 'Shrushti & Ashutosh',
    rows: 1,
    cols: 1,
  },

  // {
  //   id: 1,
  //   img: a3,
  //   title: 'Angie & Steve',
  //   rows: 1,
  //   cols: 1,
  // },

  {
    id: 3,
    img: c1,
    title: 'Jessica & Jason',
    rows: 1,
    cols: 1,
  },
  {
    id: 4,
    img: d2,
    title: 'Jenn & Yanni',
    rows: 1,
    cols: 1,
  },
  {
    id: 6,
    img: e2,
    title: 'Stephanie & Louka',
    rows: 1,
    cols: 1,
  },
  {
    id: 5,
    img: f3,
    title: 'Sumit & Rohal',
    rows: 1,
    cols: 1,
  },
  {
    id: 7,
    img: c4,
    title: 'Jessica & Jason',
    rows: 1,
    cols: 1,
  },
  {
    id: 8,
    img: i3,
    title: 'Fawziya & Mehtab',
    rows: 1,
    cols: 1,
  },
  
  // {
  //   id: 9,
  //   img: b3,
  //   title: 'Fazela & Ondrej',
  //   rows: 1,
  //   cols: 2,
  // },
  // {
  //   id: 10,
  //   img: h1,
  //   title: 'Jenn & Nicolas',
  // },
  // {
  //   id: 11,
  //   img: g1,
  //   title: 'Paulina & Louis',
  // },
  // {
  //   id: 14,
  //   img: S1,
  //   title: 'Maddison & Peter',
  // },

];

const itemDataMobile = [
  {
    id: 38,
    img: cc3,
    title: 'Monica & Adam',
    rows: 2,
    cols: 2,
  },
  {
    id: 37,
    img: bb1,
    title: 'Sonia & Bashar',
    rows: 1,
    cols: 1,
  },


  {
    id: 36,
    img: aa2,
    title: 'Travis & Cassy',
    rows: 1,
    cols: 1,
  },
  {
    id: 35,
    img: g23,
    title: 'Sarah & Gianluca',
    rows: 2,
    cols: 2,
  },
  {
    id: 34,
    img: f21,
    title: 'Carly & Ali',
    rows: 1,
    cols: 1,
  },
  {
    id: 33,
    img: e23,
    title: 'Alecia & Vidal',
    rows: 1,
    cols: 1,
  },
  {
    id: 32,
    img: d22,
    title: 'Hailey & Patrick',
    rows: 1,
    cols: 1,
  },
  {
    id: 31,
    img: c22,
    title: 'Sheba & Atilla',
    rows: 1,
    cols: 1,
  },
  {
    id: 30,
    img: b22,
    title: 'Komel & Ravin',
    rows: 1,
    cols: 1,
  },
  {
    id: 29,
    img: a21,
    title: 'Roxana & Derry',
    rows: 1,
    cols: 1,
  },
  {
    id: 28,
    img: z3,
    title: 'Noosh & Jarrett',
    rows: 1,
    cols: 1,
  },
  {
    id: 27,
    img: y1,
    title: 'Jess & Josh',
    rows: 1,
    cols: 1,
  },
  {
    id: 26,
    img: x1,
    title: 'Emily & Jordan',
    rows: 1,
    cols: 1,
  },
  {
    id: 25,
    img: w1,
    title: 'Natalie & Jean',
    rows: 1,
    cols: 1,
  },
  {
    id: 24,
    img: v3,
    title: 'Alex & Adam',
    rows: 1,
    cols: 1,
  },
  {
    id: 23,
    img: u1,
    title: 'Jelena & Vince',
    rows: 1,
    cols: 1,
  },
  {
    id: 22,
    img: t1,
    title: 'Christina & Curtis',
    rows: 1,
    cols: 1,
  },
  {
    id: 21,
    img: r2,
    title: 'Vickie & Will',
    rows: 1,
    cols: 1,
  },
  {
    id: 20,
    img: q1,
    title: 'Anita & Sidney',
    rows: 1,
    cols: 1,
  },
  {
    id: 19,
    img: p1,
    title: 'Nikoletta & Daniel',
    rows: 1,
    cols: 1,
  },
  {
    id: 18,
    img: o2,
    title: 'Brittany & Roy',
    rows: 1,
    cols: 1,
  },
  {
    id: 17,
    img: n1,
    title: 'Krystyna & Volodymyr',
    rows: 1,
    cols: 1,
  },
  {
    id: 16,
    img: m2,
    title: 'Jotika & Steve',
    rows: 1,
    cols: 1,
  },
  {
    id: 13,
    img: k2,
    title: 'Shrushti & Ashutosh',
    rows: 1,
    cols: 1,
  },
  {
    id: 15,
    img: L1,
    title: 'Abbey & Ajkurish',
    rows: 1,
    cols: 1,
  },
  {
    id: 3,
    img: c1,
    title: 'Jessica & Jason',
    rows: 2,
    cols: 1,
  },
  {
    id: 12,
    img: j1,
    title: 'Sara & Jesse',
    rows: 1,
    cols: 1,
  },

  // {
  //   id: 1,
  //   img: a3,
  //   title: 'Angie & Steve',
  //   rows: 1,
  //   cols: 1,
  // },
  // {
  //   id: 4,
  //   img: d2,
  //   title: 'Jenn & Yanni',
  //   rows: 1,
  //   cols: 1,
  // },
  // {
  //   id: 5,
  //   img: f1,
  //   title: 'Sumit & Rohal',
  //   rows: 1,
  //   cols: 1,
  // },
  // {
  //   id: 6,
  //   img: e2,
  //   title: 'Stephanie & Louka',
  //   rows: 1,
  //   cols: 1,
  // },
  // {
  //   id: 7,
  //   img: c4,
  //   title: 'Jessica & Jason',
  //   rows: 1,
  //   cols: 1,
  // },

  // {
  //   id: 10,
  //   img: h1,
  //   title: 'Jenn & Nicolas',
  //   rows: 2,
  //   cols: 1,
  // },
  // {
  //   id: 8,
  //   img: i3,
  //   title: 'Fawziya & Mehtab',
  //   rows: 1,
  //   cols: 1,
  // },
  // {
  //   id: 11,
  //   img: g1,
  //   title: 'Paulina & Louis',
  //   rows: 1,
  //   cols: 1,
  // }, 
  // {
  //   id: 14,
  //   img: S1,
  //   title: 'Maddison & Peter',
  //   rows: 1,
  //   cols: 1,
  // },
  // {
  //   id: 9,
  //   img: b2,
  //   title: 'Fazela & Ondrej',
  //   rows: 1,
  //   cols: 1,
  // },


];
// ---------------------------Custmore Images start.
const images = [
  {
    id: 38,
    img: cc3,
    name: 'Monica & Adam',
    view_img:  [ cc3, cc1, cc2],
    main_img: cc3,
  },
  {
    id: 37,
    img: bb1,
    name: 'Sonia & Bashar',
    view_img:  [ bb1, bb2, bb3],
    main_img: bb1,
  },
  {
    id: 36,
    img: aa2,
    name: 'Travis & Cassy',
    view_img:  [ aa2, aa1, aa3],
    main_img: aa2,
  },
  {
    id: 35,
    img: g23,
    name: 'Sarah & Gianluca',
    view_img:  [ g23, g21, g22],
    main_img: g23,
  },
  {
    id: 34,
    img: f21,
    name: 'Carly & Ali',
    view_img:  [f21, f22,  f23],
    main_img: f21,
  },
  {
    id: 33,
    img: e23,
    name: 'Alecia & Vidal',
    view_img:  [ e23, e21, e22],
    main_img: e23,
  },
  {
    id: 32,
    img: d22,
    name: 'Hailey & Patrick',
    view_img:  [ d22, d21, d23],
    main_img: d22,
  },
  {
    id: 31,
    img: c21,
    name: 'Sheba & Atilla',
    view_img:  [ c22, c21],
    main_img: c22,
  },
  {
    id: 30,
    img: b22,
    name: 'Komel & Ravin',
    view_img:  [ b22, b21, b23],
    main_img: b22,
  },
  {
    id: 29,
    img: a22,
    name: 'Roxana & Derry',
    view_img:  [a22, a21,  a23],
    main_img: a22,
  },
  {
    id: 28,
    img: z3,
    name: 'Noosh & Jarrett',
    view_img:  [ z3, z2, z1],
    main_img: z3,
  },
  {
    id: 27,
    img: y1,
    name: 'Jess & Josh',
    view_img:  [ y1, y2, y3],
    main_img: y3,
  },
  {
    id: 26,
    img: x1,
    name: 'Emily & Jordan',
    view_img:  [x3, x1, x2,],
    main_img: x3,
  },
  {
    id: 25,
    img: w1,
    name: 'Natalie & Jean',
    view_img:  [w3, w1, w2,],
    main_img: w3,
  },
  {
    id: 24,
    img: v3,
    name: 'Alex & Adam',
    view_img:  [v3, v1, v2,],
    main_img: v3,
  },
  {
    id: 23,
    img: u1,
    name: 'Jelena & Vince',
    view_img: [u1, u2, u3],
    main_img: u1,
  },
  {
    id: 22,
    img: t1,
    name: 'Christina & Curtis',
    view_img: [t1, t2, t3],
    main_img: t1,
  },
  {
    id: 21,
    img: r2,
    name: 'Vickie & Will',
    view_img: [r2, r1],
    main_img: r2,
  },
  {
    id: 20,
    img: q1,
    name: 'Anita & Sidney',
    view_img: [q1, q2, q3],
    main_img: q1,
  },
  {
    id: 19,
    img: p1,
    name: 'Nikoletta & Daniel',
    view_img: [p1, p2, p3],
    main_img: 11,
  },
  {
    id: 18,
    img: o2,
    name: 'Brittany & Roy',
    view_img: [o2, o1],
    main_img: o2,
  },
  {
    id: 17,
    name: "Krystyna & Volodymyr",
    view_img: [n1, n2],
    main_img: n1,
  },
  {
    id: 16,
    name: "Jotika & Steve",
    view_img: [m2, m1, m3],
    main_img: m1,
  },
  {
    id: 15,
    name: "Abbey & Ajkurish",
    view_img: [L1, L2, L3],
    main_img: L1,
  },
  // {
  //   id: 2,
  //   name: "Fazela & Ondrej",
  //   view_img: [b1, b2, b3],
  //   main_img: b1,
  // },
  {
    id: 1,
    name: "Angie & Steve",
    view_img: [a3, a1, a4],
    main_img: a3,
  },
  {
    id: 3,
    name: "Jessica & Jason",
    view_img: [c1, c2, c3],
    main_img: c1,
  },
  {
    id: 4,
    name: "Jenn & Yanni",
    view_img: [d2, d1],
    main_img: d2,
  },
  {
    id: 5,
    name: "Sumit & Rohal",
    view_img: [f1, f2, f3],
    main_img: f1,
  },
  {
    id: 6,
    name: "Stephanie & Louka",
    view_img: [e2, e4, e3],
    main_img: e2,
  },
  {
    id: 7,
    name: "Jessica & Jason",
    view_img: [c4, c2, c5],
    main_img: c5,
  },
  {
    id: 8,
    name: "Fawziya & Mehtab",
    view_img: [i3, i2, i1],
    main_img: i3,
  },
  // {
  //   id: 9,
  //   name: "Fazela & Ondrej",
  //   view_img: [b3, b4, b2],
  //   main_img: b2,
  // },
  {
    id: 10,
    name: "Jenn & Nicolas",
    view_img: [h1, h2, h3],
    main_img: h1,
  },
  {
    id: 11,
    name: "Paulina & Louis",
    view_img: [g1, g2, g3],
    main_img: g1,
  },
  {
    id: 12,
    name: "Sara & Jesse",
    view_img: [j1, j2, j3],
    main_img: j1,
  },
  {
    id: 13,
    name: 'Shrushti & Ashutosh',
    view_img: [k2, k3, k1],
    main_img: k2,
  },
  {
    id: 14,
    name: "Maddison & Peter",
    view_img: [S1, S2, S3],
    main_img: S1,
  },

];
// -------------------------------Custmore Images End.

export default function ViewCustomer() {
  const dispatch = useDispatch()
  const isBookDetailsPage = window.location.pathname.includes('/all-customer');
  const [loadmore, setLoadmore] = React.useState(3)
  const [show, setShow] = useState(false);
  const [img, setImg] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    newImage.filter((x) => (x.press_id === id ? setImg(x) : ""));
    setShow(true);
  };
console.log('Img',img);

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 992px)' })
  const isTablet = useMediaQuery({ query: ' (min-width:768px) and (max-width: 991px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const viewOurCustomers = {
    dots: false,
    infinite: true,
    speed: 500,
    autoPlay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [newImage, setNewImage] = useState()
  useEffect(() => {
    axios.get(base_url + '/common/pressMedia')
        .then(res => {
            if (res.data.status == 1) {
              dispatch(clientPraposal(res?.data?.data?.press_data))
              setNewImage(res?.data?.data?.press_data)
            }
        })
        .catch(err => {
            console.log(err)
        })
}, []);
// console.log('NewImage',newImage)
  const imagesGallery = [
    {
      original: img.images?.[0],
      thumbnail: img.images?.[0],
    },
    {
      original: img.images?.[1],
      thumbnail: img.images?.[1],
    },
    {
      original: img.images?.[2],
      thumbnail: img.images?.[2],
    },
  ];
  // console.log("imagesGallery", imagesGallery);
  return (
    <section className="dfl_view_customer_section">
      <Container>
        <Row>
          <Col sm={12} md={12} lg={12}>
<div className="title-box2 text-center">
        <h2 className="title24 dark play-font">
          They Said YES!!! To A DFL Engagement Ring
        </h2>
      </div>
          </Col>
        </Row>
      </Container>
      

      <Container className="rcs_custome_container">
        <Row>
          {isDesktopOrLaptop && 
          <LazyLoad className='lazyLoad-heroBanner' height={!isMobile?900:770} width="100%" threshold={ 0.60}>
          <Col sm={12} md={12} lg={12}>
            <div className="dfl_view_customer_final_design">
              <ImageList
                variant="quilted"
                cols={5}
                rowHeight={300}
                gap={15}
              >
                {newImage?.slice(0, 12).map((item) => (
                  <ImageListItem key={item.img} cols={item?.column || 1} rows={item?.row || 1} className="dfl_imgg_list_home">
                    <img
                      {...srcset(item.images[0], 300, item?.rows, item?.cols)}
                      alt={item?.title}
                      loading="lazy"
                      onClick={() => handleShow(item?.press_id)}
                    />
                  </ImageListItem>
                ))}


                <Modal
                  show={show}
                  onHide={handleClose}
                  size="md"
                  centered
                  className="dfl_gellery_modal pr-0 mr-0"
                >
                  <Modal.Header closeButton className="bg-white border-0">
                    <div className="d-flex justify-content-center view_custmore_header ">
                      <h3> {img?.title} </h3>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <Container>
                        <Row>
                          <Col sm={12} md={12} lg={12} className="p-0">
                            <div className="dfl_gallery_images">
                              <ImageGallery
                                items={imagesGallery}
                                showFullscreenButton={false}
                                showPlayButton={false}
                              />
                            </div>
                          </Col>

                        </Row>
                      </Container>
                    </div>
                  </Modal.Body>
                </Modal>
              </ImageList>

              <div className="view_more_customer">
                <Link to="/all-customer" className="rcs_fill_btn"> View More </Link>
              </div>
            </div>
          </Col>
          </LazyLoad>
          }
          {isTablet && <Col sm={12} md={12} lg={12}>
            <div className="dfl_view_customer_final_design">
              <ImageList

                variant="quilted"
                cols={5}
                rowHeight={160}
                gap={10}

              >
                {newImage?.map((item) => (
                  <ImageListItem key={item?.img} cols={item?.column || 1} rows={item.row || 1} className="dfl_imgg_list_home">
                    <img
                      {...srcset(item?.images[0], 200, item.rows, item.cols)}
                      alt={item?.title}
                      loading="lazy"
                      onClick={() => handleShow(item?.press_id)}
                    />
                  </ImageListItem>
                ))}



                <Modal
                  show={show}
                  onHide={handleClose}
                  size="md"
                  centered
                  className="dfl_gellery_modal pr-0 mr-0"
                >
                  <Modal.Header closeButton className="bg-white border-0">
                    <div className="d-flex justify-content-center view_custmore_header ">
                      <h3> {img?.title} </h3>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <Container>
                        <Row>
                          <Col sm={12} md={12} lg={12} className="p-0">
                            <div className="dfl_gallery_images">
                              <ImageGallery
                                items={imagesGallery}
                                showFullscreenButton={false}
                                showPlayButton={false}
                              />
                            </div>
                          </Col>

                        </Row>
                      </Container>
                    </div>
                  </Modal.Body>
                </Modal>
              </ImageList>

              {!isBookDetailsPage &&
                <div className="view_more_customer">
                  <Link to="/all-customer" className="rcs_fill_btn"> View More </Link>
                </div>
              }

            </div>
          </Col>
          }
          {isMobile && <Col sm={12} md={12} lg={12}>
            <div className="dfl_view_customer_final_design">
              <ImageList

                variant="quilted"
                cols={2}
                rows={2}
                rowHeight={150}
                gap={10}

              >
                {newImage?.slice(0, loadmore).map((item) => (
                  <ImageListItem key={item?.img} cols={item?.column || 1} rows={item?.row || 1} className="dfl_imgg_list_home">     
                    <img
                      {...srcset(item?.images[0], 300, item?.rows, item?.cols)}
                      alt={item?.title}
                      loading="lazy"
                      onClick={() => handleShow(item?.press_id)}
                    />
                  </ImageListItem>
                ))}
                <Modal
                  show={show}
                  onHide={handleClose}
                  size="md"
                  centered
                  className="dfl_gellery_modal pr-0 mr-0"
                >
                  <Modal.Header closeButton className="bg-white border-0">
                    <div className="d-flex justify-content-center view_custmore_header ">
                      <h3> {img?.title} </h3>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <Container>
                        <Row>
                          <Col sm={12} md={12} lg={12} className="p-0">
                            <div className="dfl_gallery_images">
                              <ImageGallery
                                items={imagesGallery}
                                showFullscreenButton={false}
                                showPlayButton={false}
                              />
                            </div>
                          </Col>

                        </Row>
                      </Container>
                    </div>
                  </Modal.Body>
                </Modal>
              </ImageList>
              <div className="text-center d-flex  justify-content-center">
                {loadmore == 3 ? <button type="button" class="dfl_view_btn_lod_more" onClick={() => setLoadmore(itemDataMobile.length)}>LOAD MORE</button>
                  : <div className="view_more_customer">
                    {!isBookDetailsPage && <Link to="/all-customer" className="rcs_fill_btn"> View More </Link>}
                  </div>}
              </div>
            </div>
          </Col>
          }
        </Row>
      </Container>
      {/* movile view of view customer setion */}
      <div className="dfl_view_our_customer_section_mobile_view d-none">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <Slider
                {...viewOurCustomers}
                className="dfl_view_our_customer_slider"
              >
                {images?.length > 0 &&
                  images?.map((img, i) => (
                    <div
                      className="dfl_customer_image_box"
                      onClick={() => handleShow(img?.press_id)}
                    >
                      <Image src={img?.main_img} alt="Coustomer Images" />
                    </div>
                  ))}
              </Slider>
            </Col>

          </Row>
        </Container>


      </div>
    </section>
  );
}

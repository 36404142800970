import { createSlice } from "@reduxjs/toolkit";

export const metaSlice = createSlice({
    name:'meta',
    initialState:{
        meta:[],
        clientPraposal:[]
    },
    reducers:{
        metaDetails: (state,action) => {
            state.meta = action.payload
        },
        clientPraposal: (state,action) => {
            state.clientPraposal = action.payload
        }
    }

})

export const {metaDetails,clientPraposal} = metaSlice.actions
export default metaSlice.reducer
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../Assets/css/holidayGift.css";
import { NavLink } from "react-router-dom";

const FrontHeading = () => {
 
  return (
    <>
      <div className="dfl_main_heading_Section">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="dfl_holyday_heading_main text-center">
                  <h1>Toronto Jewellery Store</h1>   
              </div>
            </Col>
          </Row>
        </Container>

      </div>
    </>
  );
};
export default FrontHeading
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import '../src/Assets/css/custome.css';
import '../src/Assets/css/color.css';
import Home from './Containers/Front/Home';
import React from 'react';
import { Suspense } from 'react';
import { Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { persistor } from './Store/store';
import { Helmet } from 'react-helmet';
const Mainrouter = React.lazy(() => import("./mainrouter"));
const GotoTop = React.lazy(() => import("./Containers/Pages/Static/GotoTop"));
const Header = React.lazy(() => import("./Containers/Header/Header"));
const Footer = React.lazy(() => import("./Containers/Footer/footer"));

const App = () => {

  useEffect(async()=>{
    await clearlocal();
  },[])

  const clearlocal = async () => {
    var localdate = localStorage.getItem('dfl-localdate');
    var date = new Date().getDate();
    if (localdate != date) {
      await persistor.flush();
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("dfl-localdate", date);
      window.location.reload()
    }
  }
  return (
    <>
    <Helmet>
    <link rel="canonical" href={window.location} />
    </Helmet>
    <BrowserRouter>
      <Suspense fallback={<><Skeltonheader /></>}>
        <Header />
      </Suspense>
      <Switch>
        <Route exact path="/" component={Home} />
        <Suspense fallback={<><Skelton /></>}>
          <Route path='*' exact={true} component={Mainrouter} />
        </Suspense>
      </Switch>
      <Suspense fallback={<><Skelton /></>}>
        <Footer />
        <GotoTop />
      </Suspense>
    </BrowserRouter>
    </>
    
  );
};
const Skelton = () => {
  return (
    <div
      className="sk"
      style={{ margin: "15px 0px", backgroundColor: "#e2e9e9" }}
    >
      <Skeleton variant="text" animation="wave" />
    </div>
  );
};
const Skeltonheader = () => {
  return (
    <div
      className="skh"
      style={{ margin: "0px 0px 10px 0px", backgroundColor: "#e2e9e9" }}
    >
      <Skeleton variant="text" animation="wave" height={75} />
    </div>
  );
};
export default App;

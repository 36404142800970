import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../Assets/css/trandingNowCenterSlider.css";

import { Col, Container, Image, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { base_url } from "../../Helpers/request";
import { useState } from "react";
import { useEffect } from "react";
import tRing16 from "../../Assets/images/DFL/trending-rings-img/yello-gold-scatter-ring.png";
import { Skeleton } from "@mui/material";

const TrandingNowCenterSlider = () => {
  const history = useHistory();
  const [trandingdata, setTrandingdata] = useState([]);
  const [loader, setLoader] = useState(false);
  
  useEffect(() => {
    setLoader(true)
    axios
      .get(base_url + "/Common/trendingjewellry")
      .then((res) => {
        if (res.data.status == 1) {
    setLoader(false)
          setTrandingdata(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleShapes = (val) => {
    var data1 = {
      style: val,
    };
    localStorage.setItem("dfl_s_filter", JSON.stringify(data1));
    history.push("/ringsettings");
    window.scrollTo(0, 0);
  };

  const options = {
    margin: 20,
    loop: true,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    center: true,
    navText: ["&#10229;", "&#10230;"],
    smartSpeed: 1000,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
      1100: {
        items: 6,
      },
    },
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12} md={12} lg={12} className="dfl_tranding_heafding_text">
            <div className="title-box2 text-center mb-sm-0">
              <h2 className="title30 dark play-font  mb-sm-0">Trending Now</h2>
            </div>
          </Col>

          <Col sm={12} md={12} lg={12} className="p-0">
            <div className="dfl_tranding_new_design_outer mt-5 mt-md-4 mt-lg-4">
              <OwlCarousel className="owl-theme" {...options}>
                {loader?<Skeleton  variant="text" animation="wave" height={100} />:trandingdata.map((item, ind) => {
                  return (
                    <div className="dfl_tranding_slide_img_outer_box">
                      <Link
                        to={
                          item.category_id == 2
                            ? "/ringsettingdetail/setting/" + item.slug
                            : "/productdetail/" + item.slug
                        }
                        key={ind}
                        class="item"
                      >
                        <Image
                          src={item?.product_image}
                          alt={item?.name}
                          className="dfl_tranding_slide_img"
                        />
                      </Link>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TrandingNowCenterSlider;
